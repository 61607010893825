















































































import { Component, PropSync } from 'vue-property-decorator'

import {
  LinkWithLabel,
  Picker,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../../../contracts'
import { LinkForm } from '../../../../molecules'

import { AbstractAddon } from '../AbstractAddon.form'

import { ImageAddon } from './Image.contracts'
import { AnyObject } from '@movecloser/front-core'
import { IFile, ImageType } from '@movecloser/ui-core'

@Component<ImageAddonForm>({
  name: 'ImageAddonForm',
  components: { LinkForm },
  mounted () {
    this.findImageRelated()
  }
})
export class ImageAddonForm extends AbstractAddon {
  @PropSync('item', { type: Object, required: true })
  public _item!: ImageAddon

  public onLinkUpdate (link: LinkWithLabel | UnresolvedLink): void {
    this._item = { ...this._item, link } as ImageAddon
  }

  public get hasImage (): boolean {
    // return !!this._item.thumbnail && typeof this._item.thumbnail.value !== 'undefined'
    return !!this.thumbnail && !!this.thumbnail.thumbnail
  }

  public onSelection (): void {
    this.findThumbnailSource()
  }

  public findThumbnailSource (): void {
    this.pickRelated(Picker.File, this.setThumbnailSource)
  }

  protected setThumbnailSource (source: Related<RelatedType.File>): void {
    this._item.thumbnail = source

    this.$nextTick(() => {
      this.findImageRelated()
    })
  }

  protected findImageRelated (): void {
    if (!this._item.thumbnail || typeof this._item.thumbnail.value === 'undefined' ||
      typeof this._item.thumbnail.type === 'undefined') {
      return
    }
    const related = this.relatedService.resolve(this._item.thumbnail, {})

    if (!(related instanceof Promise)) {
      this.getImageFromRelated(related)
      return
    }

    related.then((related: AnyObject) => {
      this.getImageFromRelated(related)
    }).catch(error => {
      console.debug(error)
    })
  }

  public imgTitle: string = ''

  public thumbnail: IFile | null = null

  protected getImageFromRelated (related: AnyObject): void {
    if (Object.prototype.hasOwnProperty.call(related, 'alt')) {
      this.imgTitle = related.title
    }

    if (Object.prototype.hasOwnProperty.call(related, 'url')) {
      this.thumbnail = {
        type: ImageType.JPG,
        thumbnail: related.url
      }

      if (Object.prototype.hasOwnProperty.call(related, 'mime')) {
        this.thumbnail.type = related.mime
      }
    }
  }

  public onRemove (): void {
    this.removeThumbnail()
  }

  public removeThumbnail (): void {
    this._item.thumbnail = {} as Related<RelatedType.File>
    this.thumbnail = null
  }
}
export default ImageAddonForm
